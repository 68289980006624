<app-page-header [header]="'Hongaarse punt vloer'" [breadcrumbs]="[
    {label: 'Home',       url: '/'},
    {label: 'Hongaarse punt', url: ''}
]"></app-page-header>

<div class="block">
    <div class="container">
        <div class="card mb-0">
            <div class="card-body contact-us">
                <div class="contact-us__container">
                    <div class="row">
                        <div class="col-12 col-lg-12  pb-lg-0">


                            <b>Bij een visgraat vloer kan iedereen zich wel iets voorstellen, maar de Hongaarse punt
                                vloer is eveneens een bijzondere vloer die echter niet bij iedereen bekend is.
                                Uitgevoerd in verschillende materialen, biedt deze vloer een prachtige aanblik. Hoe
                                wordt deze vloer gelegd en wat is het effect? </b>
                            <br><br>
                            Je hoeft geen paleis te bezitten om een <i>Hongaarse punt</i> vloer aan te schaffen. Chique
                            is het
                            wel! De Hongaarse punt is een manier van het leggen van oorspronkelijk een houten vloer.
                            Tegenwoordig kan deze vloer ook in andere materialen worden uitgevoerd zoals <a
                                routerLink="/pvc-vloeren">pvc</a> of
                            <a routerLink="/laminaat">laminaat</a>.


                            <br><br>
                            De <a href="/pvc-vloeren/visgraat">visgraat vloer</a> was de eerste bijzonder elegante vloer
                            die we in de geschiedenis
                            tegenkomen. Daarna kwam de Hongaarse punt vloer in zwang. We spreken dan over de zestiende
                            eeuw!

                            <br><br>

                            Het is de reden dat je deze vloer nog een enkele keer tegenkomt in oude kastelen. Dan is de
                            vloer uiteraard van hout. De kleine ‘gebroken’ stukjes hout verwijzen naar een stijl van
                            bouwen, die men in die tijd terug liet komen in de vloeren. Niet alleen in kastelen tref je
                            deze vloer nog wel aan, ook in sommige klassieke huizen ligt er een oorspronkelijke
                            <b>Hongaarse punt</b> vloer.

                            <br><br>

                            Voor klassieke huizen waren houten vloeren normaal. Vooral uitgevoerd in een visgraat- of
                            <b>Hongaarse punt patroon</b>. Het is daarom best bijzonder dat deze klassieke vloeren, de
                            visgraat
                            vloer en Hongaarse punt vloer, weer helemaal terug van weggeweest zijn. Het geeft een
                            interieur direct allure. De schuin gelegde houten plankjes bieden, vooral in een grote
                            ruimte, een chique aanblik en je kunt zo’n ruimte naar eigen inzicht inrichten. Met zowel
                            klassieke of moderne strakke meubels of een combinatie ervan.

                            <br><br>

                            Het mag duidelijk zijn dat je niet per se een klassieke woning hoeft te hebben om zo’n vloer
                            te laten leggen. Het is ook heel goed mogelijk om in een bestaande of nieuwbouwwoning
                            eenzelfde effect te bereiken door een visgraatvloer of Hongaarse puntvloer te laten leggen.

                            <br><br>

                            Nu zijn houten vloeren kostbaar en het vraagt nogal wat van de vaklieden die deze vloeren in
                            een zeker patroon moeten leggen. Materialen als pvc of laminaat bieden dan een fantastisch
                            en vooral betaalbaar alternatief. De visgraat is algemeen bekend, maar wat is toch die
                            Hongaarse punt vloer? We leggen beide vloeren even aan je voor.

                            <br><br>

                            <h2>Hongaarse punt of visgraat?</h2>



                            Laten we beginnen met dat bekende <a routerLink="/laminaat/visgraat">visgraatpatroon</a>.
                            Dit is een manier van het leggen van een
                            vloer waarbij de planken loodrecht op elkaar worden gelegd. Hierdoor ontstaat er een
                            visgraatpatroon, een patroon dat lijkt op de rangschikking van de graten van een vis. De
                            linkse en rechtse delen liggen onder een hoek van 90 graden. Het is van oorsprong hét
                            klassiek patroon dat vroeger in herenhuizen werd gebruikt. Zoals eerder gezegd geeft dit een
                            ruimte een luxe uitstraling.

                            <br><br>
                            <b>Visgraat vloer</b>


                            <ul>
                                <li>planken liggen loodrecht op elkaar</li>

                                <li>planken liggen onder een hoek van 90 graden tegen elkaar</li>

                                <li>klassiek patroon</li>

                                <li>trapsgewijze aanblik</li>
                            </ul>


                            Een <b>Hongaarse punt vloer</b> lijkt in eerste instantie niet te onderscheiden van de
                            visgraatvloer, maar kent toch een ander patroon. Bij deze methode worden de planken onder
                            een hoek van 45 of 60 graden gelegd. Ook dit laat een puntpatroon zien, maar net even
                            anders. Het wordt ook wel de sergeantstreep genoemd. De groeven en de belijning zijn
                            hierdoor anders en de vloer krijgt er een andere uitstraling door. Bij een visgraat vloer
                            bieden de groeven een trapsgewijze aanblik en bij de Hongaarse punt ligt het bovenste van de
                            planken tegen elkaar. Dat vormt die rechte naad.

                            <br><br>

                            <b>Hongaarse punt vloer</b>

                            <ul>
                                <li>planken liggen onder een hoek van 45 of 60 graden</li>

                                <li>wordt sergeantstreep genoemd </li>

                                <li>bovenste delen van de planken sluiten op elkaar aan</li>


                                <li>aanblik van een rechte naad</li>
                            </ul>


                            Wat mooier is, is natuurlijk heel persoonlijk. Volgens vaklieden oogt de <i>Hongaarse
                                punt</i> iets
                            rustiger.


                            <br><br>
                            <h2>Hongaarse punt Laminaat</h2>



                            Nu waren de vloeren vroeger vooral van massief hout en kostbaar, de huidige vloeren in
                            <i>Hongaarse punt</i> gelegd zijn onder meer van laminaat. Een goedkoper en duurzaam
                            alternatief
                            waar de manier van leggen ook fantastisch mooi in uitkomt.

                            <br><br>

                            Laminaat, dat in de jaren zeventig van de vorige eeuw zijn intrede deed, bestaat uit
                            verschillende op elkaar gelijmde lagen met een toplaag van slijtvast kunststof. De term
                            <a href="/laminaat">laminaat</a> komt dan ook voort uit de benaming voor de dunne laagjes
                            over elkaar geplaatst
                            materiaal, de lamelle. Kliklaminaat bestaat overigens pas sinds eind jaren negentig.

                            <br><br>

                            Voor laminaat heb je de keuze uit verschillende kleuren en afwerkingen. Je kunt de vloer
                            vrij egaal houden of juist kiezen voor planken met wat kleurverschil, waardoor het effect
                            speelser is. Afhankelijk van je inrichting kun je een eigen keuze maken. In alle gevallen
                            biedt deze vloer een warme uitstraling, kun je er alle kanten mee op en je woning er een
                            heel persoonlijke touch mee geven.

                            <br><br>

                            <b>De voordelen van laminaat</b>

                            <ul>
                                <li>makkelijk in onderhoud</li>

                                <li>slijtvast</li>

                                <li>betaalbaar</li>

                                <li>geschikt voor <a routerLink="/ondervloeren/vloerverwarming">vloerverwarming</a></li>

                                <li>duurzaam</li>

                                <li>voelt goed aan doordat het een dikke laag vormt (inclusief ondervloer)</li>

                            </ul>


                            <span style="text-align: center; width: 100%; display: inline-block; font-weight: bold;">Decoren uit de <a
                                    routerLink="/laminaat/quickstep/impressive-patterns">Quickstep Impressive
                                    Patterns</a> serie</span>
                            <div class="row justify-content-center">
                                <div class="col-6 col-lg-2">

                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4160_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium">
                                                    QuickStep Impressive Patterns IPA4160 Eik visgraat medium</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4161_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur">
                                                    QuickStep Impressive Patterns IPA4161 Eik Visgraat Natuur</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4162_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin">
                                                    QuickStep Impressive Patterns IPA4162 Eik Visgraat Bruin</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4164-hongaarse-punt_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe">
                                                    QuickStep Impressive Patterns IPA4164 Eik Visgraat Taupe</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <br><br>

                            <h2>Hongaarse punt PVC</h2>



                            De Hongaarse puntvloer is eveneens in <a routerLink="/pvc-vloeren">pvc</a> uitvoerbaar.
                            Professioneel en vakkundig gelegd
                            door vloerenleggers. Een Hongaarse punt vraagt immers om een scherp oog en ervaring. Ook de
                            pvc vloer geeft een bijzonder fraaie uitstraling en is een duurzaam alternatief voor de
                            kostbare houten vloer in Hongaarse punt gelegd.



                            PVC is een zachter materiaal dan laminaat. Het maakt dan ook minder contactgeluid. Pvc is
                            gemaakt van polyvinylchloride. Dit is een dun, soepel en makkelijk te bewerken materiaal.
                            Het is in ieder geval een dunnere vloer dan de laminaatvloer, die bestaat uit samengeperste
                            lagen. Het is belangrijk dat een pvc vloer op een egale ondergrond wordt gelegd. Vaak moet
                            de vloer dan ook eerst geëgaliseerd worden. De pvc vloer kan ook over een bestaande vloer
                            worden gelegd, mits deze uiteraard goed vlak is. Ook een pvc vloer is verkrijgbaar in
                            meerdere kleuren en dessins.

                            <br><br>

                            <B>De voordelen van pvc</B>

                            <ul>
                                <li>sterke vloer met toplaag</li>

                                <li>onderhoudsvriendelijk</li>

                                <li>geschikt voor <a routerLink="/ondervloeren/vloerverwarming">vloerverwarming</a></li>

                                <li>geluiddempende eigenschappen</li>

                                <li>bestand tegen vocht</li>

                                <li>lange levensduur</li>
                            </ul>



                            Waar je ook maar voor kiest -visgraat of Hongaarse punt, laminaat of pvc- deze vloeren
                            bieden een klassieke en elegante look die fantastisch combineert met verschillende wijzen
                            van het stijlen van een woning. Klassieke- of boerenmeubels, een vintage- of moderne
                            inrichting, minimalistisch of juist een kleurrijk en vol interieur; alles is mogelijk in
                            combinatie met de op een bijzondere wijze gelegde vloerdelen in visgraat- of <a
                                routerLink="">Hongaarse punt patroon</a>.
                            <br><br>
                            <span style="text-align: center; width: 100%; display: inline-block; font-weight: bold;"> Decoren uit de <a
                                    routerLink="/pvc-vloeren/douwes-dekker/ambitieus-hongaarse-punt">Douwes Dekker
                                    Ambitieus hongaarse punt</a> serie</span>

                            <div class="row justify-content-center">
                                <div class="col-6 col-lg-2">

                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/douwes-dekker-hongaarse-punt-spekkoek_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek">
                                                    Douwes Dekker Ambitieus Hongaarse Punt Spekkoek</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 58.50
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/douwes-dekker-hongaarse-punt-boterkoek_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek">
                                                    Douwes Dekker Ambitieus Hongaarse Punt Boterkoek</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 58.50
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
