<app-page-header [breadcrumbs]="[
    {label: 'Home', url: '/'},
    {label: 'Combinatie Korting', url: ''}
]"></app-page-header>



<div class="container">
    <div class="document">
        <div class="document__header">
            <h1 class="document__title">Combinatie korting</h1>
        </div>
        <div class="document__content typography">
            <div id="infopagenew" class="contentbluelink">

                Voordeelvloeren is voordelig met laminaat en PCV vloeren maar ook met alle toebehoren zoals plinten,
                profielen en ondervloeren. Maak je een combinatie van een vloer + ondervloer kunnen we vaak nog extra
                korting geven op de prijs van de ondervloer.
                <br><br>
                Je vindt de combinatieprijs bij elke laminaat en PVC vloer. Lees of scrol even verder bij elke vloer en
                selecteer de
                beste ondervloer. De prijs van de ondervloeren zijn dan extra verlaagd en kun je precies zien wat je
                extra kunt
                besparen.
                <br>
                <h2>Reken voorbeeld </h2>
                Stel je bestelt 18 pakken <a routerLink="/laminaat/quickstep/capture">Quickstep Capture</a> in combinatie
                met een <a routerLink="/product/279/timbersound-10-m2">TimberSound</a> ondervloer dan
                is de prijs van de ondervloer niet € 69.50 per rol maar slechts € 49.50 per rol. Een voordeel van €
                20.00 per rol en € 80.00 extra korting bij afname van 4 rollen.
                <br><br>
                Je hebt dan niet alleen de mooiste Quick-Step laminaatvloer, maar ook de beste geluiddempende ondervloer die ook geschikt is voor vloerverwarming. De warmteweerstand is slechts 0,071 m<sup>2</sup> K/W voor laminaatvloer + TimberSound ondervloer.
Bij iedere laminaat of PVC vloer vind je de combinatie korting met ondervloer naar keuze voor elke kamer in huis of appartement.

                <br>

                <img class="d-none d-lg-block" src="/assets/images/pages/combinatie-korting/voorbeeld-desktop.webp" alt="combinatie korting">

                <img class="d-none d-md-block d-lg-none" src="/assets/images/pages/combinatie-korting/voorbeeld-tablet.webp" alt="combinatie korting">

                <div style="width: 100%; text-align: center;" class="d-block d-md-none"><img src="/assets/images/pages/combinatie-korting/voorbeeld-mobile.webp" alt="combinatie korting"></div>


                <h2 style="margin-top: 10px;">Extra {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}% betalingskorting bij iDeal</h2>
                Betaal jij met iDeal vinden wij dat fijn omdat het tijd bespaart bij het afleveren van je vloer en
                belonen we je met {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}% korting op het totaalbedrag. iDeal is makkelijk en veilig en {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}% korting snel
                verdient.

                <br>
                <h2>De beste prijs met de beste service!</h2>
                Ja, dat kan! Wij zijn ervan overtuigd dat wij dat bieden. Een goede prijs voor kwaliteitsvloeren,
                thuisbezorgt door chauffeurs van Voordeelvloeren, netjes binnen afgeleverd achter de eerste deur op de
                begane grond. Jij geeft aan welke dag het beste uitkomt en wij geven een dag voor levering de
                aankomsttijd aan je door. Bekijk de <a routerLink="/klantenservice/bezorgen-afhalen">bezorgservice</a> en de
                mogelijkheden die we
                bieden.
                <br><br>
                Heb je teveel PVC of laminaat bestelt, dan komen we dit zonder bijkomende kosten weer retour halen.
                Bekijk de <a routerLink="/klantenservice/retourneren">gratis retourservice</a>. Net zoals we het brengen,
                halen we het
                ook weer op.
                <br><br>
                Kunnen we nog iets anders voor je betekenen, kun je dat vragen bij de <a
                    routerLink="/klantenservice">klantenservice</a> en
                zullen we alles in het werk stellen dat voor je te
                realiseren.



            </div>
        </div>
    </div>
</div>
