import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SeoService } from '../../../../shared/services/seo.service';

@Component({
    selector: 'app-faq',
    templateUrl: './page-faq.component.html',
    styleUrls: ['./page-faq.component.scss']
})
export class PageFaqComponent implements OnInit {
    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private router: Router,
        private seoService: SeoService,
        private sanitizer: DomSanitizer
    ) {}

    obj: string;
    faqs: any[] = [];

    categorietitel: string;
    categorieseourl: string;
    slug: string;
    type: string;
    titel: string;
    //public isCollapsed = false;

    public isCollapsed = true;
    public rows: object[] = [];

    ngOnInit() {
        if (
            this.route.snapshot.url.length > 1 &&
            typeof this.route.snapshot.url[1]['path'] !== 'undefined' &&
            this.route.snapshot.url[1]['path'].includes('onderwerp')
        ) {
            this.type = 'cat';
        } else {
            this.type = 'faq';
        }

        if (
            this.route.snapshot.params.faqSlug !== '' &&
            typeof this.route.snapshot.params.faqSlug !== 'undefined'
        ) {
            this.slug = this.route.snapshot.params.faqSlug;
        } else {
            this.slug = '';
        }

        this.httpClient
            .get(
                'https://api.voordeelvloeren.nl/page-faq.php?type=' +
                    this.type +
                    '&id=' +
                    this.slug,
                { responseType: 'text' }
            )
            .subscribe((response) => {
                this.obj = JSON.parse(response);

                if (!this.obj) {
                    return;
                }

                for (let i = 0; i < this.obj.length; i++) {
                    this.rows.push({
                        title: this.obj[i]['title'],
                        intro: this.obj[i]['intro'],
                        full: this.sanitizer.bypassSecurityTrustHtml(
                            this.obj[i]['body']
                        ),
                        url: this.obj[i]['url'],
                        isCollapsed: true
                    });

                    //   this.categorietitel = this.obj[0]['categorietitel'];
                    //   this.categorieseourl = this.obj[0]['categorieseourl'];

                    //this.titleService.setTitle(this.obj[0]['categorietitel'] + ' | Voordeelvloeren');
                    //this.metaService.addTag({ name: 'description', content: "AAA" + this.obj[i]['metadesc'] });

                    if (this.type === 'cat') {
                        this.titel = this.obj[0]['categorietitel'];
                        this.titleService.setTitle(
                            'Veelgestelde vragen - ' +
                                this.obj[0]['categorietitel'] +
                                ' | Voordeelvloeren'
                        );
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                this.obj[0]['categorietitel'] +
                                ' veelgestelde vragen over laminaatvloeren'
                        });
                        this.categorieseourl = this.obj[0]['categorieseourl'];
                    }
                    if (this.type === 'faq') {
                        this.titel = this.obj[0]['title'];
                        this.titleService.setTitle(
                            this.obj[0]['title'] + ' | Voordeelvloeren'
                        );
                        this.metaService.updateTag({
                            name: 'description',
                            content: this.obj[0]['metadesc']
                        });
                        this.categorietitel = this.obj[0]['categorietitle'];
                        this.categorieseourl = this.obj[0]['categorieseourl'];
                    }

                    if (this.type === '') {
                        this.titleService.setTitle(
                            'Veelgestelde vragen | Voordeelvloeren'
                        );
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                'Top 10 veelgestelde vragen over laminaatvloeren'
                        });
                        this.categorieseourl = this.obj[0]['categorieseourl'];
                    }
                }
            });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl' + this.router.url
        );
    }

    getRoute(event) {
        if (!event.target.getAttribute('href').includes('http')) {
            event.preventDefault();
            this.router.navigate([event.target.getAttribute('href')]);
        }
    }
}
