import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SeoService } from '../../../../shared/services/seo.service';

@Component({
    selector: 'app-sitemap',
    templateUrl: './page-sitemap.component.html',
    styleUrls: ['./page-sitemap.component.scss']
})
export class PageSitemapComponent implements OnInit {
    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private router: Router,
        private seoService: SeoService
    ) {}

    obj: string;
    faqs: any[] = [];

    categorietitel: string;
    categorieseourl: string;
    slug: string;
    type: string;
    titel: string;
    //public isCollapsed = false;

    public isCollapsed = true;
    public rows: object[] = [];

    ngOnInit() {
        if (
            this.route.snapshot.params.sitemapSlug !== '' &&
            typeof this.route.snapshot.params.sitemapSlug !== 'undefined'
        ) {
            this.slug = this.route.snapshot.params.sitemapSlug;
        } else {
            this.slug = '';
        }

        this.httpClient
            .get(
                'https://api.voordeelvloeren.nl/sitemap.php?page=' + this.slug,
                {
                    responseType: 'text'
                }
            )
            .subscribe((response) => {
                switch (this.slug) {
                    case 'algemeen':
                        this.titel = 'Sitemap - algemeen';
                        break;
                    case 'faq':
                        this.titel = 'Sitemap - Veelgestelde vragen';
                        break;
                    case 'merken':
                        this.titel = 'Sitemap - merken';
                        break;
                    case 'series':
                        this.titel = 'Sitemap - series';
                        break;
                    case '1':
                        this.titel = 'Sitemap - Artikel 1 t/m 100';
                        break;
                    case '2':
                        this.titel = 'Sitemap - Artikel 101 t/m 200';
                        break;
                    case '3':
                        this.titel = 'Sitemap - Artikel 201 t/m 300';
                        break;
                    case '4':
                        this.titel = 'Sitemap - Artikel 301 t/m 400';
                        break;
                    case '5':
                        this.titel = 'Sitemap - Artikel 401 t/m 500';
                        break;
                    case '6':
                        this.titel = 'Sitemap - Artikel 501 t/m 600';
                        break;
                    case '7':
                        this.titel = 'Sitemap - Artikel 601 t/m 700';
                        break;
                    case '8':
                        this.titel = 'Sitemap - Artikel 701 t/m 800';
                        break;
                    case '9':
                        this.titel = 'Sitemap - Artikel 801 t/m 900';
                        break;
                    case '10':
                        this.titel = 'Sitemap - Artikel 901 t/m 1000';
                        break;
                    case '11':
                        this.titel = 'Sitemap - Artikel 1001 t/m 1100';
                        break;
                    case '12':
                        this.titel =
                            'Sitemap - Artikel 1101 t/m laatste artikel';
                        break;
                    default:
                        this.titel = 'Sitemap';
                        break;
                }

                this.obj = JSON.parse(response);

                if (!this.obj) {
                    return;
                }

                for (let i = 0; i < this.obj.length; i++) {
                    this.rows.push({
                        url: this.obj[i]['url'],
                        name: this.obj[i]['name']
                    });

                    if (this.slug == '') {
                        this.titleService.setTitle(
                            'Sitemap overzicht - Voordeevloeren'
                        );
                        this.metaService.updateTag({
                            name: 'description',
                            content: "Sitemap overzicht van alle pagina's"
                        });
                    } else {
                        this.titleService.setTitle(
                            'Sitemap page ' + this.slug + ' - Voordeevloeren'
                        );
                        this.metaService.updateTag({
                            name: 'description',
                            content: 'Sitemap pagina ' + this.slug
                        });
                    }

                    // if (this.type === 'cat') {
                    //     this.titel = this.obj[0]['categorietitel'];
                    //     this.titleService.setTitle(
                    //         'Veelgestelde vragen - ' +
                    //             this.obj[0]['categorietitel'] +
                    //             ' | Voordeelvloeren'
                    //     );
                    //     this.metaService.updateTag({
                    //         name: 'description',
                    //         content:
                    //             this.obj[0]['categorietitel'] +
                    //             ' veelgestelde vragen over laminaatvloeren'
                    //     });
                    //     this.categorieseourl = this.obj[0]['categorieseourl'];
                    // }
                    // if (this.type === 'faq') {
                    //     this.titel = this.obj[0]['title'];
                    //     this.titleService.setTitle(
                    //         this.obj[0]['title'] + ' | Voordeelvloeren'
                    //     );
                    //     this.metaService.updateTag({
                    //         name: 'description',
                    //         content: this.obj[0]['metadesc']
                    //     });
                    //     this.categorietitel = this.obj[0]['categorietitle'];
                    //     this.categorieseourl = this.obj[0]['categorieseourl'];
                    // }

                    // if (this.type === '') {
                    //     this.titleService.setTitle(
                    //         'Veelgestelde vragen | Voordeelvloeren'
                    //     );
                    //     this.metaService.updateTag({
                    //         name: 'description',
                    //         content:
                    //             'Top 10 veelgestelde vragen over laminaatvloeren'
                    //     });
                    //     this.categorieseourl = this.obj[0]['categorieseourl'];
                    // }
                }
            });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl' + this.router.url
        );
    }

    getRoute(event) {
        if (!event.target.getAttribute('href').includes('http')) {
            event.preventDefault();
            this.router.navigate([event.target.getAttribute('href')]);
        }
    }
}
