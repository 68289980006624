import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../../../../shared/services/seo.service';
import { Router } from '@angular/router';
import { CustomGlobalConstants } from '../../../../shared/constants/constants';

@Component({
    selector: 'app-combinatie-korting',
    templateUrl: './page-combinatie-korting.component.html',
    styleUrls: ['./page-combinatie-korting.component.scss']
})
export class PageCombinatieKortingComponent {
    iDealKortingPercentage: number;

    constructor(
        private titleService: Title,
        private metaService: Meta,
        private seoService: SeoService,
        private router: Router
    ) {
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
    }
    ngOnInit(): void {
        this.titleService.setTitle('Korting | Voordeelvloeren.nl');
        this.metaService.updateTag({
            name: 'description',
            content:
                'Kortingscode hebben we niet, maar wel aantrekkelijke prijzen zonder kortingscodes. Extra aanbieding als je een combinatie besteld van een vloer + ondervloer.'
        });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl' + this.router.url
        );
    }
}
