import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../../../../shared/services/seo.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-terms',
    templateUrl: './page-meisterlindura.component.html',
    styleUrls: ['./page-meisterlindura.component.scss']
})
export class PageMeisterLinduraComponent {
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private seoService: SeoService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(
            'Meister Lindura | Beter dan Parket | Mooier dan laminaat'
        );
        this.metaService.updateTag({
            name: 'description',
            content:
                'Meister Lindura vloer te kopen. Geschikt voor vloerverwarming, mooie lange brede planken, slijtvast en supersterk. Laat je overtuigen dat de Lindura collectie van Meister de mooiste vloer is met de beste prijs.'
        });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl' + this.router.url
        );
    }
}
