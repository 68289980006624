<app-page-header [header]="'Veelgestelde vragen'" [breadcrumbs]="[
    {label: 'Home',                        url: '/'},
    {label: 'Veelgestelde vragen',  url: '/faq'}
]"></app-page-header>

<div class="block faq">
    <div class="container">



        <div class="row">
            <div class="col-12 col-lg-3 d-flex">
                <div class="account-nav flex-grow-1">
                    <h4 class="account-nav__title">Onderwerpen</h4>
                    <ul>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === ''}"><a href=" /faq">Top
                                10</a>
                        </li>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === 'algemeen'}"><a
                                href="/faq/onderwerp/algemeen">Algemeen</a></li>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === 'inmeten'}"><a
                                href="/faq/onderwerp/inmeten">Inmeten</a></li>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === 'bestellen-levering'}"><a
                                href="/faq/onderwerp/bestellen-levering">Bestellen en levering</a></li>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === 'prijzen-aanbiedingen'}">
                            <a href="/faq/onderwerp/prijzen-aanbiedingen">Prijzen en aanbiedingen</a>
                        </li>
                        <li class="account-nav__item ng-star-inserted"
                            [ngClass]="{'account-nav__item--active': this.categorieseourl === 'ondervloeren'}"><a
                                href="/faq/onderwerp/ondervloeren">Ondervloeren</a></li>


                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-9 mt-4 mt-lg-0">
                <div class="dashboard">
                    <div class=" card">
                        <div class="card-header">
                            <h2>{{this.titel}}</h2>
                        </div>
                        <div _ngcontent-serverapp-c141="" class="card-divider"></div>
                        <div style="padding: 0px 14px 28px 24px;">
                            <div class="row">

                                <div class="typography" *ngFor="let row of rows; index as i"
                                    style="width: 100%; margin-top: 15px; margin-bottom: 5px; padding-bottom: 0px;">

                                    <div *ngIf="!row.url" [innerHTML]="row.full" (click)="getRoute($event)"></div>
                                    <div *ngIf="!row.url"><br><a routerLink="/faq/onderwerp/{{this.categorieseourl}}">
                                            <img src="/assets/images/global/arrow-blue.svg"
                                                style="width: 10px; float: left; margin-top: 5px; margin-right: 10px; transform: rotate(180deg);" alt="Blauwe pijl">
                                            Terug naar overzicht</a></div>

                                    <div class="row" *ngIf="row.url" style="padding-bottom: 10px; border-bottom: 2px solid #f0f0f0;">
                                        <div class="col-1" style="min-width: 35px;">
                                            <img (click)="row.isCollapsed = !row.isCollapsed" *ngIf="row.isCollapsed"
                                                src="/assets/images/global/arrow-blue.svg"
                                                style="width: 10px; transform: rotate(90deg); float: left; margin-top: 10px; margin-right: 10px;" alt="Blauwe pijl">
                                            <img (click)="row.isCollapsed = !row.isCollapsed" *ngIf="!row.isCollapsed"
                                                src="/assets/images/global/arrow-blue.svg"
                                                style="width: 10px; transform: rotate(-90deg); float: left; margin-top: 10px; margin-right: 10px;" alt="Blauwe pijl">
                                        </div>
                                        <div class="col">
                                            <span (click)="row.isCollapsed = !row.isCollapsed"
                                                style="float: left; margin-top: 5px; font-weight: bold; ">{{row.title}}</span>
                                            <p [collapse]="row.isCollapsed" [isAnimated]="true" style="float: left;">
                                                <span [innerHTML]="row.intro"></span>
                                                <br><br>

                                                <a routerLink="/faq/{{row.url}}">
                                                    <img src="/assets/images/global/arrow-blue.svg"
                                                        style="width: 10px; float: left; margin-top: 5px; margin-right: 10px;" alt="Blauwe pijl">
                                                    Lees verder</a>
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
