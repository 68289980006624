import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../../../../shared/services/seo.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-terms',
    templateUrl: './page-hongaarsepunt.component.html',
    styleUrls: ['./page-hongaarsepunt.component.scss']
})
export class PageHongaarsepuntComponent {
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private seoService: SeoService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(
            'Hongaarse Punt | Klassieke laminaat en PVC vloeren'
        );
        this.metaService.updateTag({
            name: 'description',
            content:
                'Wat is het verschil tussen parket gelegd in Hongaarse punt en visgraat. De meestal eiken plankjes hebben een schuine kant aan de uiteinde van plank.'
        });
        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl' + this.router.url
        );
    }
}
