<app-page-header [header]="'Meister Lindura vloeren'" [breadcrumbs]="[
    {label: 'Home',       url: '/'},
    {label: 'Meister Lindura vloeren', url: ''}
]"></app-page-header>

<div class="block">
    <div class="container">
        <div class="card mb-0">
            <div class="card-body contact-us">
                <div class="contact-us__container">
                    <div class="row">
                        <div class="col-12 col-lg-12  pb-lg-0">

                            <h2>Wat is een Meister Lindura vloer?</h2>

                            De Lindura collectie van Meister is sterk, slijtvast, mooi en uniek. <b>Meister
                                Lindura</b>
                            is géén laminaat maar ook géén parketvloer. Wel heeft de Lindura vloer van zowel
                            laminaat
                            als parket de <b>beste eigenschappen</b>.

                            <br><br>
                            <h2>Voordeel van Lindura op parketvloeren</h2>
                            Geen enkele parketplank is identiek. De toplaag van de Lindura vloer is van <b>echt
                                houtfineer</b> en geen enkele plank is hetzelfde. Je ziet géén verschil tussen parket en
                            Lindura door ernaar te kijken of op te lopen.
                            <br><br>
                            Het grote voordeel van Lindura vloeren ten op zichtte van parket is de hardheid van de
                            vloer. Vallende voorwerpen, naaldhakken, stoelpoten enzovoort drukken veel makkelijker in
                            een parketvloer dan in een <b>Lindura vloer</b>. De kern van een Lindura vloer is een HDF
                            plank. HDF is een extra hard geperste MDF plaat. Aan beide zijde wordt een houtfineer onder
                            hoge druk en temperatuur geperst met behulp van de woodpowder techniek.
                            <br><br>



                            <!-- <b>
                                <ul>
                                    <li>Lindura HD400 mat gelakt – 20 x 220 cm</li>
                                    <li>Lindura HD 400 mat gelakt – 27 x 220 cm</li>
                                </ul>
                                <br>
                                <ul>
                                    <li>Lindura HD 400 natuur geolied – 27 x 220 cm</li>
                                    <li>Lindura HD 400 natuur geolied – 32 x 260 cm</li>
                                </ul>
                                <br>
                                <ul>
                                    <li>Lindura HS 500 visgraat mat gelakt – 14 x 70 cm</li>
                                </ul>
                            </b> -->



                            <div class="row g-3">

                                <div class="col-sm-12 col-lg-6">
                                    <a routerLink="/laminaat/meister/lindura-hd-400-natuurgeolied-270" class="text-decoration-none">
                                        <div class="container_foto3 drieluik4">
                                            <div class="ver_mas text-center">
                                                <span class="lnr lnr-eye">MEISTER ASSORTIMENT</span>
                                            </div>
                                            <article class="text-left">
                                                <div class="whitebg">
                                                    <h2 style="color: #5e5351; border-bottom: #5e5351 solid 1px;">
                                                        Lindura HD 400 - 270</h2>
                                                    <h4 style="color: #5e5351;">Natuurgeolied - Afm. 27 x 220 cm</h4>
                                                </div>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-12 col-lg-6">
                                    <a routerLink="/laminaat/meister/lindura-hd-400-natuurgeolied-320" class="text-decoration-none">
                                        <div class="container_foto3 drieluik5">
                                            <div class="ver_mas text-center">
                                                <span class="lnr lnr-eye">MEISTER ASSORTIMENT</span>
                                            </div>
                                            <article class="text-left">
                                                <div class="whitebg">
                                                    <h2 style="color: #5e5351; border-bottom: #5e5351 solid 1px;">
                                                        Lindura HD 400 - 320</h2>
                                                    <h4 style="color: #5e5351;">Natuurgeolied - Afm. 14 x 70 cm</h4>
                                                </div>
                                            </article>
                                        </div>
                                    </a>
                                </div>

                            </div>





                            <br>
                            <h2>Voordeel van Lindura op laminaat</h2>
                            Lindura vloeren worden net zoals een laminaatvloer aan elkaar geklikt. Aan alle 4 zijden van
                            de plank zit een mes of groef. De zijkant van de Linduraplank met mes en groefzijde zijn
                            geïmpregneerd om <b>24 uur water te weerstaan</b> op de toplaag van de vloer. De Lindura
                            vloer is ook geschikt als keukenvloer of als vloer in de hal. Gebruik bij de voordeur en
                            achterdeur wel altijd een goede schoonloopmat.
                            <br><br>
                            <h2>Lindura voordeliger dan Parket</h2>
                            Om een Lindura vloer te maken heb ongeveer <b>70% minder bomen</b> nodig in verhouding met
                            een massieve parketvloer. Goed voor het milieu maar ook voor je portemonnee. Edelhout is het
                            kostbaarste gedeelte van een Lindura vloer en daar is maar 1mm fineer van nodig.
                            <br><br>
                            Een parketvloer wordt meestal na het verlijmen op een betonvloer geschuurd en gelakt,
                            geschuurd en gelakt en nog een keer geschuurd en gelakt. Kost tijd, geeft veel stof en de
                            vloer is niet direct beloopbaar. <br>
                            Meister werkt de Lindura vloer af met olie of een extreme sterke matte lak. Je plaatst de
                            <i>Linduravloer</i> op je juiste ondervloer en is direct klaar om op te lopen of te
                            verhuizen.
                            <br><br>
                            Een <i>Linduravloer</i> is ook makkelijk weer te verwijderen als je bijvoorbeeld een huis
                            leeg moet opleveren en opnieuw te plaatsen door het handige kliksysteem dat Meister heeft
                            gebruikt voor de Lindura vloer.
                            <br><br>

                            <div class="row g-3">
                                <div class="col-sm-12 col-lg-4">
                                    <a routerLink="/laminaat/meister/lindura-hd-400-ultramat-gelakt-205"
                                        class="text-decoration-none">
                                        <div class="container_foto drieluik1">
                                            <div class="ver_mas text-center">
                                                <span class="lnr lnr-eye">MEISTER ASSORTIMENT</span>
                                            </div>
                                            <article class="text-left">
                                                <div class="whitebg">
                                                    <h2 style="color: #5e5351; border-bottom: #5e5351 solid 1px;">
                                                        Lindure HD 400 - 205</h2>
                                                    <h4 style="color: #5e5351;">Ultramat gelakt - Afm. 20 x 220 cm</h4>
                                                </div>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <a routerLink="/laminaat/meister/lindura-hd-400-ultramat-gelakt-270" class="text-decoration-none">
                                        <div class="container_foto drieluik2">
                                            <div class="ver_mas text-center">
                                                <span class="lnr lnr-eye">MEISTER ASSORTIMENT</span>
                                            </div>
                                            <article class="text-left">
                                                <div class="whitebg">
                                                    <h2 style="color: #5e5351; border-bottom: #5e5351 solid 1px;">
                                                        Lindura HD 400 - 270</h2>
                                                    <h4 style="color: #5e5351;">Ultramat Gelakt - Afm. 27 x 220 cm</h4>
                                                </div>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <a routerLink="/laminaat/meister/lindura-hs-500-ultramat-gelakt-visgraat" class="text-decoration-none">
                                        <div class="container_foto drieluik3">
                                            <div class="ver_mas text-center">
                                                <span class="lnr lnr-eye">MEISTER ASSORTIMENT</span>
                                            </div>
                                            <article class="text-left">
                                                <div class="whitebg">
                                                    <h2 style="color: #5e5351; border-bottom: #5e5351 solid 1px;">
                                                        Lindura HS 500 - Visgraat</h2>
                                                    <h4 style="color: #5e5351;">Ultramat Gelakt - Afm. 14 x 70 cm</h4>
                                                </div>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <br>
                            <h2>Lange en brede Lindura planken</h2>
                            Nieuw in het assortiment van Lindura is de breedte maat 20.5 cm. Best smal ten opzichte van
                            de 27 cm en 32 cm planken, maar 20.5 cm is een brede plank. Met de lengte van 220 cm is dit
                            de ideale plank voor wat kleinere ruimtes. De prijs is de voordeliger omdat fineer van 20 cm
                            goedkoper is dan breder fineer. De kwaliteit van de instap Lindura vloer is identiek aan de
                            bredere Lindura planken.<br>
                            De HDF tussenlaag van bijna 11 mm is zo sterk dat zelfs de brede planken van 32 cm keurig
                            recht blijven en niet schotelen, barsten of bol gaan staan.
                            <br><br>

                            <h2>Meister Lindura met vloerverwarming</h2>
                            Een belangrijk voordeel van de Lindura vloer is de lage warmte weerstand ten opzichte van
                            een parket of lamelparketvloer. De regel is dat de totale warmte weerstand lager moet zijn
                            dan 0.140 m² K/W om je huis makkelijk en snel te verwarmen. <br>
                            Alle Lindura vloeren hebben een warmtegeleiding van 0.084 m² K/W en dat is héél erg laag
                            voor een vloer van 11 mm. Tel daarbij de meest gangbare <a
                                href="/product/279/timbersound-10-m2">TimberSound</a> ondervloer bij op met een
                            weerstand van 0.010 m² K/W en is de complete weerstand 0.094 m² K/W. Ruim beneden de
                            grenswaarde.
                            <br><br>
                            Voor appartementen met vloerverwarming is een 10dB certificaat nodig en dan is bij een
                            vlakke betonvloer <a href="/product/269/heatfoil-15-m2-10db">Heatfoil</a> de
                            beste ondervloer onder de Meister Lindura vloer. De warmte weerstand van Heatfoil is 0.014
                            m² K/W en is de totale weerstand met de Lindura vloer 0.098 m² K/W. Perfecte combinatie.
                            <br><br>
                            Bestel je een Linduravloer + een ondervloer dan ontvang je <b>extra korting</b> op de
                            ondervloer. De <a href="/combinatie-korting">combinatie korting</a>zie je direct op de
                            pagina van de Lindura vloer van jouw keuze staan. Selecteer de ondervloer en zie direct
                            hoeveel je extra bespaart.
                            <br><br>

                            <div class="row g-3">

                                <div class="col-sm-12 col-md-6">
                                    <a routerLink="/klantenservice/showroom" class="text-decoration-none">
                                        <div class="container_foto2" style="background-image: url('/assets/images/kanweg/home41.webp');
        background-size: cover;">

                                            <article class="text-left">
                                                <h2>Showroom</h2>
                                                <h4>Bekijk ons ruime assortiment in onze showroom</h4>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <a routerLink="/klantenservice/showroom" class="text-decoration-none">
                                        <div class="container_foto2" style="background-image: url('/assets/images/kanweg/home42.webp');
        background-size: cover;">

                                            <article class="text-left">
                                                <h2>Persoonlijk advies</h2>
                                                <h4>Onze ervaren adviseurs helpen je
                                                    graag&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                                            </article>
                                        </div>
                                    </a>
                                </div>
                            </div>


                            <br>
                            <h2>Mooie Lindura stalen in onze showroom</h2>
                            In onze showroom in Wormerveer tonen we graag de decoren en kwaliteit die een Linduravloer
                            bezit. Wil je een staaltje meenemen krijg je die van ons gratis. Ook kunnen we een offerte
                            voor je opstellen en uitleggen hoe je de Lindura vloer nog mooier kan afwerken met de beste
                            ondervloer, bijpassende plinten en perfect kunt onderhouden.<br>
                            Heb je nog vragen over deze unieke Lindura vloer, neem dan contact op met de <a
                                href="/klantenservice">klantenservice</a> via mail, telefoon, chat of Whatsapp.
                            <br><br>
                            <h2>Meister Lindura overzicht en afmetingen</h2>
<Br>
                            <table class="table table-hover">
                                <thead>
                                    <tr class="hideonmobile" >
                                        <th scope="col">Serie</th>
                                        <th scope="col">Afmetingen</th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <th scope="row">Lindura HD 400 Natuurgeolied 270</th>
                                        <td>27 x 220 cm</td>
                                        <td><a href="/laminaat/meister/lindura-hd-400-natuurgeolied-270">Bekijk Meister
                                                Lindura HD 400 Natuurgeolied 270</a></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Lindura HD 400 Natuurgeolied 320</th>
                                        <td>32 x 260 cm</td>
                                        <td><a href="/laminaat/meister/lindura-hd-400-natuurgeolied-320">Bekijk Meister
                                                Lindura HD 400 Natuurgeolied 320</a></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Lindure HD 400 Ultramat gelakt 205</th>
                                        <td>20 x 220 cm</td>
                                        <td><a href="/laminaat/meister/lindura-hd-400-ultramat-gelakt-205">Bekijk
                                                Meister Lindure HD 400 Ultramat gelakt 205</a></td>

                                    </tr>
                                    <tr>
                                        <th scope="row">Lindura HD 400 Ultramat Gelakt 270</th>
                                        <td>27 x 220 cm</td>
                                        <td><a href="/laminaat/meister/lindura-hd-400-ultramat-gelakt-270">Bekijk
                                                Meister Lindura HD 400 Ultramat Gelakt 270</a></td>

                                    </tr>
                                    <tr>
                                        <th scope="row">Lindura HS 500 Ultramat Gelakt Visgraat</th>
                                        <td>14 x 70 cm</td>
                                        <td><a href="/laminaat/meister/lindura-hs-500-ultramat-gelakt-visgraat">Bekijk
                                                Meister Lindura HS 500 Ultramat Gelakt Visgraat</a></td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--

                            <b>Bij een visgraat vloer kan iedereen zich wel iets voorstellen, maar de Hongaarse punt
                                vloer is eveneens een bijzondere vloer die echter niet bij iedereen bekend is.
                                Uitgevoerd in verschillende materialen, biedt deze vloer een prachtige aanblik. Hoe
                                wordt deze vloer gelegd en wat is het effect? </b>
                            <br><br>
                            Je hoeft geen paleis te bezitten om een <i>Hongaarse punt</i> vloer aan te schaffen. Chique
                            is het
                            wel! De Hongaarse punt is een manier van het leggen van oorspronkelijk een houten vloer.
                            Tegenwoordig kan deze vloer ook in andere materialen worden uitgevoerd zoals <a
                                routerLink="/pvc-vloeren">pvc</a> of
                            <a routerLink="/laminaat">laminaat</a>.


                            <br><br>
                            De <a href="/pvc-vloeren/visgraat">visgraat vloer</a> was de eerste bijzonder elegante vloer
                            die we in de geschiedenis
                            tegenkomen. Daarna kwam de Hongaarse punt vloer in zwang. We spreken dan over de zestiende
                            eeuw!

                            <br><br>

                            Het is de reden dat je deze vloer nog een enkele keer tegenkomt in oude kastelen. Dan is de
                            vloer uiteraard van hout. De kleine ‘gebroken’ stukjes hout verwijzen naar een stijl van
                            bouwen, die men in die tijd terug liet komen in de vloeren. Niet alleen in kastelen tref je
                            deze vloer nog wel aan, ook in sommige klassieke huizen ligt er een oorspronkelijke
                            <b>Hongaarse punt</b> vloer.

                            <br><br>

                            Voor klassieke huizen waren houten vloeren normaal. Vooral uitgevoerd in een visgraat- of
                            <b>Hongaarse punt patroon</b>. Het is daarom best bijzonder dat deze klassieke vloeren, de
                            visgraat
                            vloer en Hongaarse punt vloer, weer helemaal terug van weggeweest zijn. Het geeft een
                            interieur direct allure. De schuin gelegde houten plankjes bieden, vooral in een grote
                            ruimte, een chique aanblik en je kunt zo’n ruimte naar eigen inzicht inrichten. Met zowel
                            klassieke of moderne strakke meubels of een combinatie ervan.

                            <br><br>

                            Het mag duidelijk zijn dat je niet per se een klassieke woning hoeft te hebben om zo’n vloer
                            te laten leggen. Het is ook heel goed mogelijk om in een bestaande of nieuwbouwwoning
                            eenzelfde effect te bereiken door een visgraatvloer of Hongaarse puntvloer te laten leggen.

                            <br><br>

                            Nu zijn houten vloeren kostbaar en het vraagt nogal wat van de vaklieden die deze vloeren in
                            een zeker patroon moeten leggen. Materialen als pvc of laminaat bieden dan een fantastisch
                            en vooral betaalbaar alternatief. De visgraat is algemeen bekend, maar wat is toch die
                            Hongaarse punt vloer? We leggen beide vloeren even aan je voor.

                            <br><br>

                            <h2>Hongaarse punt of visgraat?</h2>



                            Laten we beginnen met dat bekende <a routerLink="/laminaat/visgraat">visgraatpatroon</a>.
                            Dit is een manier van het leggen van een
                            vloer waarbij de planken loodrecht op elkaar worden gelegd. Hierdoor ontstaat er een
                            visgraatpatroon, een patroon dat lijkt op de rangschikking van de graten van een vis. De
                            linkse en rechtse delen liggen onder een hoek van 90 graden. Het is van oorsprong hét
                            klassiek patroon dat vroeger in herenhuizen werd gebruikt. Zoals eerder gezegd geeft dit een
                            ruimte een luxe uitstraling.

                            <br><br>
                            <b>Visgraat vloer</b>


                            <ul>
                                <li>planken liggen loodrecht op elkaar</li>

                                <li>planken liggen onder een hoek van 90 graden tegen elkaar</li>

                                <li>klassiek patroon</li>

                                <li>trapsgewijze aanblik</li>
                            </ul>


                            Een <b>Hongaarse punt vloer</b> lijkt in eerste instantie niet te onderscheiden van de
                            visgraatvloer, maar kent toch een ander patroon. Bij deze methode worden de planken onder
                            een hoek van 45 of 60 graden gelegd. Ook dit laat een puntpatroon zien, maar net even
                            anders. Het wordt ook wel de sergeantstreep genoemd. De groeven en de belijning zijn
                            hierdoor anders en de vloer krijgt er een andere uitstraling door. Bij een visgraat vloer
                            bieden de groeven een trapsgewijze aanblik en bij de Hongaarse punt ligt het bovenste van de
                            planken tegen elkaar. Dat vormt die rechte naad.

                            <br><br>

                            <b>Hongaarse punt vloer</b>

                            <ul>
                                <li>planken liggen onder een hoek van 45 of 60 graden</li>

                                <li>wordt sergeantstreep genoemd </li>

                                <li>bovenste delen van de planken sluiten op elkaar aan</li>


                                <li>aanblik van een rechte naad</li>
                            </ul>


                            Wat mooier is, is natuurlijk heel persoonlijk. Volgens vaklieden oogt de <i>Hongaarse
                                punt</i> iets
                            rustiger.


                            <br><br>
                            <h2>Hongaarse punt Laminaat</h2>



                            Nu waren de vloeren vroeger vooral van massief hout en kostbaar, de huidige vloeren in
                            <i>Hongaarse punt</i> gelegd zijn onder meer van laminaat. Een goedkoper en duurzaam
                            alternatief
                            waar de manier van leggen ook fantastisch mooi in uitkomt.

                            <br><br>

                            Laminaat, dat in de jaren zeventig van de vorige eeuw zijn intrede deed, bestaat uit
                            verschillende op elkaar gelijmde lagen met een toplaag van slijtvast kunststof. De term
                            <a href="/laminaat">laminaat</a> komt dan ook voort uit de benaming voor de dunne laagjes
                            over elkaar geplaatst
                            materiaal, de lamelle. Kliklaminaat bestaat overigens pas sinds eind jaren negentig.

                            <br><br>

                            Voor laminaat heb je de keuze uit verschillende kleuren en afwerkingen. Je kunt de vloer
                            vrij egaal houden of juist kiezen voor planken met wat kleurverschil, waardoor het effect
                            speelser is. Afhankelijk van je inrichting kun je een eigen keuze maken. In alle gevallen
                            biedt deze vloer een warme uitstraling, kun je er alle kanten mee op en je woning er een
                            heel persoonlijke touch mee geven.

                            <br><br>

                            <b>De voordelen van laminaat</b>

                            <ul>
                                <li>makkelijk in onderhoud</li>

                                <li>slijtvast</li>

                                <li>betaalbaar</li>

                                <li>geschikt voor <a routerLink="/ondervloeren/vloerverwarming">vloerverwarming</a></li>

                                <li>duurzaam</li>

                                <li>voelt goed aan doordat het een dikke laag vormt (inclusief ondervloer)</li>

                            </ul>


                            <span
                                style="text-align: center; width: 100%; display: inline-block; font-weight: bold;">Decoren
                                uit de <a routerLink="/laminaat/quickstep/impressive-patterns">Quickstep Impressive
                                    Patterns</a> serie</span>
                            <div class="row justify-content-center">
                                <div class="col-6 col-lg-2">

                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4160_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium">
                                                    QuickStep Impressive Patterns IPA4160 Eik visgraat medium</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2440/quickstep-impressive-patterns-ipa4160-eik-visgraat-medium">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4161_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur">
                                                    QuickStep Impressive Patterns IPA4161 Eik Visgraat Natuur</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2441/quickstep-impressive-patterns-ipa4161-eik-visgraat-natuur">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4162_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin">
                                                    QuickStep Impressive Patterns IPA4162 Eik Visgraat Bruin</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2442/quickstep-impressive-patterns-ipa4162-eik-visgraat-bruin">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/quickstep-impressive-patterns-ipa-4164-hongaarse-punt_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe">
                                                    QuickStep Impressive Patterns IPA4164 Eik Visgraat Taupe</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 28.95
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2443/quickstep-impressive-patterns-ipa4164-eik-visgraat-taupe">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <br><br>

                            <h2>Hongaarse punt PVC</h2>



                            De Hongaarse puntvloer is eveneens in <a routerLink="/pvc-vloeren">pvc</a> uitvoerbaar.
                            Professioneel en vakkundig gelegd
                            door vloerenleggers. Een Hongaarse punt vraagt immers om een scherp oog en ervaring. Ook de
                            pvc vloer geeft een bijzonder fraaie uitstraling en is een duurzaam alternatief voor de
                            kostbare houten vloer in Hongaarse punt gelegd.



                            PVC is een zachter materiaal dan laminaat. Het maakt dan ook minder contactgeluid. Pvc is
                            gemaakt van polyvinylchloride. Dit is een dun, soepel en makkelijk te bewerken materiaal.
                            Het is in ieder geval een dunnere vloer dan de laminaatvloer, die bestaat uit samengeperste
                            lagen. Het is belangrijk dat een pvc vloer op een egale ondergrond wordt gelegd. Vaak moet
                            de vloer dan ook eerst geëgaliseerd worden. De pvc vloer kan ook over een bestaande vloer
                            worden gelegd, mits deze uiteraard goed vlak is. Ook een pvc vloer is verkrijgbaar in
                            meerdere kleuren en dessins.

                            <br><br>

                            <B>De voordelen van pvc</B>

                            <ul>
                                <li>sterke vloer met toplaag</li>

                                <li>onderhoudsvriendelijk</li>

                                <li>geschikt voor <a routerLink="/ondervloeren/vloerverwarming">vloerverwarming</a></li>

                                <li>geluiddempende eigenschappen</li>

                                <li>bestand tegen vocht</li>

                                <li>lange levensduur</li>
                            </ul>



                            Waar je ook maar voor kiest -visgraat of Hongaarse punt, laminaat of pvc- deze vloeren
                            bieden een klassieke en elegante look die fantastisch combineert met verschillende wijzen
                            van het stijlen van een woning. Klassieke- of boerenmeubels, een vintage- of moderne
                            inrichting, minimalistisch of juist een kleurrijk en vol interieur; alles is mogelijk in
                            combinatie met de op een bijzondere wijze gelegde vloerdelen in visgraat- of <a
                                routerLink="">Hongaarse punt patroon</a>.
                            <br><br>
                            <span style="text-align: center; width: 100%; display: inline-block; font-weight: bold;">
                                Decoren uit de <a
                                    routerLink="/pvc-vloeren/douwes-dekker/ambitieus-hongaarse-punt">Douwes Dekker
                                    Ambitieus hongaarse punt</a> serie</span>

                            <div class="row justify-content-center">
                                <div class="col-6 col-lg-2">

                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/douwes-dekker-hongaarse-punt-spekkoek_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek">
                                                    Douwes Dekker Ambitieus Hongaarse Punt Spekkoek</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 58.50
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2866/douwes-dekker-ambitieus-hongaarse-punt-spekkoek">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-2">
                                    <div class="product-card">
                                        <div style="padding: 8px; flex-shrink: 0;"><a class="ng-star-inserted"
                                                ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                href="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek"><img
                                                    loading="lazy" class="responsiveimg"
                                                    alt="Maison 1520 Bordeaux Eiken"
                                                    src="https://storage.googleapis.com/voordeelvloeren-c005d.appspot.com/douwes-dekker-hongaarse-punt-boterkoek_276x276.jpg"
                                                    width="200" height="200"></a></div>
                                        <div class="product-card__info" style="padding: 2px 8px 8px 8px;">
                                            <div class="product-card__name"><a appclick=""
                                                    ng-reflect-router-link="/product/1534/maison-breed-plu"
                                                    href="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek">
                                                    Douwes Dekker Ambitieus Hongaarse Punt Boterkoek</a></div>
                                        </div>
                                        <div class="product-card__actions"
                                            style="width: 100%; text-align: center; padding-bottom: 8px;">
                                            <div class="product-card__prices"
                                                style="font-size: 18px; padding: 5px 0px 8px 0px;">€ 58.50
                                                m<sup>2</sup>
                                            </div>
                                            <div><button type="button" class="btn btn-primary product-card__addtocart"
                                                    tabindex="0"
                                                    ng-reflect-router-link="/product/2865/douwes-dekker-ambitieus-hongaarse-punt-boterkoek">
                                                    Informatie </button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
 -->


                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
